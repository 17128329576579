import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./blog.scss"

const Blog = ({ data }) => {
  return (
    <Layout>
    <Seo title="Blog" />
      <div className="d-flex flex-column flex-fill">
        <div className="container-fluid">
          <h1>
          Thoughts in progress
          </h1>
          <h4>Check it out I have {data.allMarkdownRemark.totalCount} post{data.allMarkdownRemark.totalCount > 1 && 's'}</h4>
          {data.allMarkdownRemark.edges.map(({ node }) => (
              <Link
                key={node.id}
                className="blog-link blog-card-container d-flex p-4 col-12 col-sm-6"
                to={node.fields.slug}>
                  <div className="">
                    <h3>
                      {node.frontmatter.title}                
                    </h3>
                    <h6>
                      <span>
                        — {node.frontmatter.date}
                      </span>
                    </h6>
                    {node.frontmatter.excerpt}
                  </div>
              </Link>
          ))}
        </div>
        <div className="flex-fill row page-bg bg-clip"></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            excerpt
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Blog